import React from 'react';

import Layout from '../components/Layout';

const IndexPage = (props) => (
  <Layout fullMenu location={props.location}>
    <article id="main">
      <header>
        <h2>Not Found</h2>
        <p>Not a Valid URL</p>
      </header>
    </article>
  </Layout>
);

export default IndexPage;
